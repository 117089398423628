<template>
  <div class="container">
    <div class="container_left">
      <div class="container_left_top">
        <p class="yulan">{{ t('390') }}</p>
        <div class="message">
          <img :src="logo" />
          <p class="descript">{{ setForm.organizationName }}</p>
        </div>
      </div>
      <div class="container_left_bottom">
        <div class="pc" @click="showPC" :class="{ pc_active: pcbackground }">
          <span
            class="iconfont icon-diannao"
            :class="{ is_active: textcolor }"
          ></span>
          <span :class="{ is_active: textcolor }">{{ t('753') }}</span>
          <div class="line" v-if="pccustomize"></div>
        </div>
        <div
          class="log"
          @click="showLog"
          :class="{ log_active: logbackground }"
        >
          <span
            class="iconfont icon-rizhi1"
            :class="{ is_active: logcolor }"
          ></span>
          <span :class="{ is_active: logcolor }">{{ t('754') }}</span>
          <div class="line" v-if="logpart"></div>
        </div>
      </div>
    </div>
    <div class="container_right">
      <div class="pc_customize" v-if="pccustomize">
        <div class="title">
          <p>{{ t('753') }}</p>
        </div>
        <div class="changesize">
          <div class="system_name">
            <div class="changesize_left">
              <span>{{ t('755') }}：</span>
              <el-input
                v-model="systemName"
                v-if="isEditSystemName"
                @keyup="systemName = systemName.replace(/[ ]/g, '')"
                maxlength="10"
                ref="systemInput"
                @blur="text"
              >
                >
              </el-input>
              <span v-else class="inputname">{{ setForm.systemName }}</span>
              <p style="color: red; margin-left: 4.8rem" v-if="showError">
                {{ t('756') }}
              </p>
            </div>
            <div
              class="changesize_right hover_opacity"
              v-if="showEdit"
              @click="editSystemName"
            >
              <span class="edit iconfont icon-bianjishuru"></span>
              <span>{{ t('674') }}</span>
            </div>
            <div class="changesize_right_button" v-if="showEditButton">
              <button class="cancel hover_opacity" @click="cancelEdit">
                {{ t('217') }}
              </button>
              <button class="enter hover_opacity" @click="changeSystemName">
                {{ t('218') }}
              </button>
            </div>
          </div>
          <div class="changefontsize">
            <span>{{ t('757') }}：</span>
            <div class="radio_list">
              <el-radio-group
                v-model="setForm.systemNameSize"
                @change="changeSize()"
              >
                <el-radio :label="'1.5'">24</el-radio>
                <el-radio :label="'1.75'">28</el-radio>
                <el-radio :label="'2'">32</el-radio>
                <el-radio :label="'2.25'">36</el-radio>
                <el-radio :label="'2.5'">40</el-radio>
                <el-radio :label="'2.75'">44</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="name">
          <div class="changesize_left">
            <span>{{ t('758') }}：</span>
            <el-input
              v-model="organizationName"
              v-if="isEditorgName"
              @keyup="organizationName = organizationName.replace(/[ ]/g, '')"
              maxlength="15"
              @blur="text1"
            ></el-input>
            <span v-else class="inputname">{{ setForm.organizationName }}</span>
            <p style="color: red; margin-left: 4.8rem" v-if="showError1">
              {{ t('759') }}
            </p>
          </div>
          <div class="changesize_right hover_opacity" v-if="showorgEdit" @click="editOrganzationName">
            <span class="edit iconfont icon-bianjishuru"></span>
            <span>{{ t('674') }}</span>
          </div>
          <div class="changesize_right_button" v-if="showorgEditButton">
            <button class="cancel hover_opacity" @click="cancelorgEdit">
              {{ t('217') }}
            </button>
            <button class="enter hover_opacity" @click="changeorgName">
              {{ t('218') }}
            </button>
          </div>
        </div>
        <div class="region">
          <div class="openArea">
            <div class="openAreaBox flex">
              <div>{{ t('760') }}：</div>
              <div class="openAreaBox_R flex">
                <div class="tree">
                  <div v-show="showOpenArea" class="initOpenModel">
                    <el-tree
                      ref="initOpenCityRef"
                      :data="openCity"
                      :props="cityProps"
                      node-key="adcode"
                      show-checkbox
                    />
                    <div class="openModel" @click="modelEdit"></div>
                  </div>
                  <div class="treeBox" v-show="!showOpenArea">
                    <el-tree
                      ref="openCityRef"
                      :data="openCity"
                      :props="cityProps"
                      node-key="adcode"
                      show-checkbox
                    />
                  </div>
                </div>
                <div class="flex-a flex-je Edit">
                  <div
                    class="changesize_right hover_opacity"
                    v-if="showOpenArea"
                    @click="editOpenArea"
                  >
                    <span class="edit iconfont icon-bianjishuru"></span>
                    <span>{{ t('674') }}</span>
                  </div>
                  <div class="changesize_right_button" v-if="!showOpenArea">
                    <button
                      class="cancel hover_opacity"
                      @click="cancelOpenArea"
                    >
                      {{ t('217') }}
                    </button>
                    <button class="enter hover_opacity" @click="submitOpenArea">
                      {{ t('218') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="initArea flex-a">
            <div class="initAreaBox flex">
              <div>{{ t('761') }}：</div>
              <div class="initAreaBox_R flex">
                <div class="regionText" v-if="showInitArea">
                  {{ regionName }}
                </div>
                <el-cascader
                  v-if="!showInitArea"
                  class="common-input"
                  style="width: 65%"
                  :show-all-levels="false"
                  v-model="region"
                  :placeholder="t('762')"
                  :options="city"
                  :props="cityCasDer"
                  @change="cityChange"
                  popper-class="teamedit disabled_hide_cacader"
                />
                <div class="flex-a flex-je Edit">
                  <div
                    class="changesize_right hover_opacity"
                    v-if="showInitArea"
                    @click="editInitArea"
                  >
                    <span class="edit iconfont icon-bianjishuru"></span>
                    <span>{{ t('674') }}</span>
                  </div>
                  <div class="changesize_right_button" v-if="!showInitArea">
                    <button
                      class="cancel hover_opacity"
                      @click="cancelInitArea"
                    >
                      {{ t('217') }}
                    </button>
                    <button class="enter hover_opacity" @click="submitInitArea">
                      {{ t('218') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="last">
          <div class="last_container">
            <div class="logo_title">
              <p>{{ t('763') }}LOGO:</p>
            </div>
            <div class="logo_container">
              <div class="logo_show">
                <img :src="logo" />
              </div>
              <div class="logo_descript">
                <el-upload
                  action="actionUrl"
                  :show-file-list="false"
                  :http-request="httpUploadFile"
                  accept=".png, .jpg, .svg"
                >
                  <el-button type="primary" class="hover_opacity">
                    <span
                      class="iconfont icon-shangchuan"
                      style="color: #00c0e8"
                    ></span
                    >&nbsp;&nbsp;{{ t('764') }}
                  </el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
        <div class="lang_box select-wrapper">
          <label class="label" >{{ t('765') }}：</label>
          <el-select v-model="currLang" @change="changeLang">
            <el-option
              v-for="item in langOption"
              :key="item.value"
              :label="item.desc"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="log_part" v-if="logpart">
        <div class="log_title">
          <p>{{ t('754') }}</p>
        </div>
        <div class="log_container">
          <div class="log_radio">
            <el-radio-group v-model="radioDay" @change="changeDate()">
              <el-radio :label="30">30天</el-radio>
              <el-radio :label="180">{{ t('766') }}</el-radio>
              <el-radio :label="360">{{ t('767') }}</el-radio>
            </el-radio-group>
            <el-button
              type="primary"
              @click="exportExcel()"
              class="hover_opacity"
            >
              <span class="iconfont icon-bendishangchuan"></span
              >&nbsp;&nbsp;{{ t('647') }}
            </el-button>
          </div>
          <div class="log_box">
            <p v-for="(item, index) in logs" :key="index">
              {{
                item.createTime
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                item.logMessage
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { t } from '../../languages';
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
  watch,
} from "vue";
import { ElMessage } from "element-plus";
import syssetting from "@/network/system";
import download from "@/network/download";
import common from "@/network/common";
import { store } from "@/store";
import {
  findP,
  flatten,
  isEqual,
  getAdcode,
  cityMap,
} from "@/utils/openRegion";
import { injectVoerkaI18n } from '@voerkai18n/vue'

export default defineComponent({
  setup() {
    const actionUrl = computed(() => {
      return process.env.VUE_APP_BASE_API + "/file/v1/file/upload";
    });
    const region = ref(""); //选择区域
    const regionName = ref("");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userId = userInfo.id;
    // 开放区域
    const openCity = ref([]);
    fetch("json/openMapTree.json")
      .then((d) => d.json())
      .then((res) => {
        openCity.value = res[0].children;
      });

    const openCityRef = ref(null);
    const initOpenCityRef = ref(null);
    const cityProps = {
      children: "children",
      label: "name",
    };

    // 初始区域
    const city = ref([]);
    fetch("json/map-tree.json")
      .then((d) => d.json())
      .then((res) => {
        city.value = res[0].children;
      });

    const state = reactive({
      pccustomize: true,
      logpart: false,
      textcolor: true,
      logcolor: false,
      pcbackground: true,
      logbackground: false,
      systemName: "",
      organizationName: "",
      isEditSystemName: false,
      isEditorgName: false,
      showEdit: true,
      showEditButton: false,
      showorgEdit: true,
      showorgEditButton: false,
      radio: "",
      radioDay: "",
      setForm: {
        systemName: "",
        organizationName: "",
        systemNameSize: "24",
        openRegion: [],
        defaultRegion: 0,
      },
      logs: [], //系统日志
      logo: "", //logo地址
      showError: false,
      showError1: false,
    });
    onMounted(() => {
      methods.getSystemMessage();
      methods.getSystemLog();
      methods.getLogDate();
      methods.getSystemLogo();
    });
    const systemInput = ref(null);
    const text = () => {
      if (state.systemName === "") {
        state.showError = true;
      }
    };
    const text1 = () => {
      if (state.organizationName === "") {
        state.showError1 = true;
      }
    };
    const methods = reactive({
      //获取系统日志
      getSystemLog: async () => {
        const res = await syssetting.getLog();
        state.logs = res.resultData;
      },
      //获取日志天数
      getLogDate: async () => {
        const res = await syssetting.getLogDay();
        state.radioDay = res.resultData;
      },
      //修改日志天数
      changeDate: () => {
        syssetting.reviseDate(state.radioDay).then(() => {
          methods.getSystemLog();
          ElMessage.closeAll();
          ElMessage.success(t("714"));
        });
      },
      //导出文件
      exportExcel: async () => {
        await download.downloadSource(
          "/api/v1/log/export",
          "application/vnd.ms-excel",
          "get"
        );
      },
      //上传文件
      httpUploadFile(option) {
        common.uploadLogo({ file: option.file }, true).then((res) => {
          if(res.resultStatus){
            state.logo = res.resultData.url;
            syssetting.uploadLogo({ url: state.logo });
            store.dispatch("SystemLogo", state.logo);
          }
        });
      },
      //系统名称
      changeSystemName: async () => {
        if (state.systemName === "") {
          return;
        }
        state.showError = false;
        state.isEditSystemName = false;
        state.showEdit = true;
        state.showEditButton = false;
        const res = await syssetting.systemConfig({
          systemName: state.systemName,
        });
        state.setForm.systemName = state.systemName;
        methods.getSystemMessage();
        if (res.resultStatus) {
          ElMessage.success(t("714"));
          store.commit("updateSystemInfo", state.setForm);
        }
      },
      // 改变字号大小
      changeSize: async () => {
        const res = await syssetting.systemConfig({
          systemNameSize: state.setForm.systemNameSize,
        });
        ElMessage.closeAll();
        if (res.resultStatus) {
          store.dispatch("Changesize", state.setForm.systemNameSize);
          ElMessage.success(t("714"));
        }
      },
      //组织名称
      changeorgName: async () => {
        if (state.organizationName === "") {
          return;
        }
        state.showError1 = false;
        state.isEditorgName = false;
        state.showorgEditButton = false;
        state.showorgEdit = true;
        const res = await syssetting.systemConfig({
          organizationName: state.organizationName,
        });
        state.setForm.organizationName = state.organizationName;
        methods.getSystemMessage();
        if (res.resultStatus) {
          ElMessage.success(t("714"));
          store.commit("updateSystemInfo", state.setForm);
        }
      },
      //获取系统配置信息
      getSystemMessage: async () => {
        const res = await syssetting.getSystemData();
        if (res.resultStatus) {
          state.setForm = res.resultData;
          region.value = res.resultData.defaultRegion;
          regionName.value = cityMap[res.resultData.defaultRegion];
        }
      },
      //获取logo
      getSystemLogo: async () => {
        const res = await syssetting.getLogoURL();
        state.logo = res.resultData;
      },
      showPC: () => {
        state.pccustomize = true;
        state.logpart = false;
        state.textcolor = true;
        state.logcolor = false;
        state.pcbackground = true;
        state.logbackground = false;
      },
      showLog: () => {
        state.logpart = true;
        state.pccustomize = false;
        state.textcolor = false;
        state.logcolor = true;
        state.pcbackground = false;
        state.logbackground = true;
      },
      editSystemName: () => {
        state.isEditSystemName = true;
        state.showEditButton = true;
        state.showEdit = false;
        state.systemName = state.setForm.systemName;
      },
      editOrganzationName: () => {
        state.isEditorgName = true;
        state.showorgEditButton = true;
        state.showorgEdit = false;
        state.organizationName = state.setForm.organizationName;
      },
      cancelEdit: () => {
        state.showError = false;
        state.isEditSystemName = false;
        state.showEdit = true;
        state.showEditButton = false;
      },
      cancelorgEdit: () => {
        state.showError1 = false;
        state.isEditorgName = false;
        state.showorgEditButton = false;
        state.showorgEdit = true;
      },
    });
    // 开放区域
    const cityCasDer = {
      value: "adcode",
      label: "title",
      checkStrictly: true,
      children: "children",
      disabled: "disabled",
    };
    const showOpenArea = ref(true);
    const showInitArea = ref(true);

    const editOpenArea = () => {
      showOpenArea.value = !showOpenArea.value;
      resetChecked();
    };
    const cancelOpenArea = () => {
      showOpenArea.value = true;
      resetChecked();
    };
    // 提交
    const submitOpenArea = async () => {
      const key = openCityRef.value.getCheckedKeys(false, true);
      if(key.length === 0) return(ElMessage.warning(t("768")));//判空
      let isRegoinCode =  region.value instanceof Object ? region.value[0]:region.value;
      const isOpenKey = key.findIndex(item=>item === isRegoinCode);
      if(isOpenKey === -1) return(ElMessage.warning(t("769")));//判是否存在存在初始区域
      const arr = JSON.stringify(key);
      const res = await syssetting.systemConfig({
        openRegion: arr,
      });
      if (res.resultStatus) {
        state.setForm.openRegion = key;
        store.dispatch("openRegion", state.setForm.openRegion);
      }
      showOpenArea.value = true;
    };
    // 编辑会先匹配
    const resetChecked = () => {
      const isCountry = state.setForm.openRegion.some((item) => {
        return item === 1;
      });
      if (isCountry) {
        if(showOpenArea.value){
          initOpenCityRef.value?.setCheckedKeys([1], false);
        }else{
            const cityList = city.value.map((item) => {
              return item.adcode; });
            openCityRef.value?.setCheckedKeys(cityList, false);
        }   
      } else {
        let special= [710000,810000,820000];
        const cityList = city.value.map((item) => {
          return item.adcode;
        });
        //无子区域的adcode香港台湾澳门
        const d = state.setForm.openRegion.filter(
          (v) => special.findIndex((el) => el === v) !=-1
        );
        // 有子区域的adcode
        const c = state.setForm.openRegion.filter(
          (v) => cityList.findIndex((el) => el === v) === -1
        );
        openCityRef.value?.setCheckedKeys([...d,...c], false);
      }
    };
    //初始化
    const initOpenCity = () => {
      const isCountry = state.setForm.openRegion.some((item) => {
        return item === 1;
      });
      if (
        (state.setForm.openRegion[0] === 1 &&
          state.setForm.openRegion.length === 1) ||
        isCountry
      ) {
        initOpenCityRef.value?.setCheckedKeys([1], false);
      } else {
        const cityList = city.value.map((item) => {
          return item.adcode;
        });
        const c = state.setForm.openRegion.filter(
          (v) => cityList.findIndex((el) => el === v) === -1
        );
        initOpenCityRef.value?.setCheckedKeys(c, false);
      }
    };

    // 编辑初始区域加禁用状态
    const editInitArea = () => {
      showInitArea.value = !showInitArea.value;
      const isCountry = state.setForm.openRegion.some((item) => {
        return item === 1;
      });
      if (isCountry) {
        return;
      } else {
        const cityList = city.value.map((item) => {
          return item.adcode;
        });
        const arr = state.setForm.openRegion;
        // 这里漏了没有某个省没有全选的情况
        const c = state.setForm.openRegion.filter(
          (v) => cityList.findIndex((el) => el === v) != -1
        );
        const faterAdcode = arr.map((item) => {
          return findP(item, openCity.value);
        });
        const getFaterAdcode = flatten(faterAdcode);
        getFaterAdcode.map((item, index) => {
          if (item === 1) {
            getFaterAdcode.splice(index, 1);
          }
        });
        const getNeedAdcode = getFaterAdcode.filter(
          (v) => arr.findIndex((el) => el === v) === -1
        ); //去重过滤掉拿到没有全选的省adcode
        const needAdcode = [...c, ...getNeedAdcode]; //合并拿到打勾的省adcode
        const acceptAdcode = cityList.filter(
          (v) => needAdcode.findIndex((el) => el === v) === -1
        ); //过滤掉需要的adcode
        // 添加禁用属性
        city.value.map((item) => {
          acceptAdcode.forEach((items) => {
            if (items === item.adcode) {
              item.disabled = true;
            }
          });
        });
      }
    };
    // 取消初始区域编辑
    const cancelInitArea = () => {
      showInitArea.value = true;
      region.value = state.setForm.defaultRegion;
    };
    const submitInitArea = async () => {
      const defaultRegion = region.value;
      const needRegoin =
        typeof defaultRegion === "object" ? defaultRegion[0] : defaultRegion;
      const res = await syssetting.systemConfig({
        defaultRegion: needRegoin,
      });
      if (res.resultStatus) {
        state.setForm.defaultRegion = defaultRegion;
        store.dispatch("defaultRegion", state.setForm.defaultRegion);
        city.value.forEach((item) => {
          if (item.adcode === Number(region.value)) {
            regionName.value = item.name;
          }
        });
        showInitArea.value = true;
      }
    };
    const modelEdit = () => {
      ElMessage.warning(t("770"));
    };
    const nodeClick = () => {
      if (showOpenArea.value) return ElMessage.warning(t("770"));
    };
    const cityChange = () => {};
    watch(
      () => state.setForm.openRegion,
      (newName) => {
        initOpenCity();
      }
    );

    // 语言下拉列表
    const langOption = [
      {
        desc: '简体中文',
        value: 'zh'
      },
      {
        desc: 'English',
        value: 'en'
      }
    ]
    
    const currLang = ref(store.state.system.language)    // 当前语言
    const i18n = injectVoerkaI18n()
    /* 修改语言 */
    function changeLang(){
      localStorage.setItem('language', currLang.value)
      store.commit("updateLanguage", currLang.value);
      i18n.activeLanguage = currLang.value
      location.reload()
    }

    return {
      ...toRefs(state),
      ...toRefs(methods),
      text,
      actionUrl,
      systemInput,
      text1,
      cityCasDer,
      showOpenArea,
      showInitArea,
      editOpenArea,
      cancelOpenArea,
      submitOpenArea,
      editInitArea,
      cancelInitArea,
      submitInitArea,
      modelEdit,
      nodeClick,
      cityChange,
      city,
      region,
      regionName,
      openCity,
      cityProps,
      openCityRef,
      initOpenCityRef,
      resetChecked,
      initOpenCity,
      langOption,
      currLang,
      changeLang,
    };
  },
});
</script>

<style lang='less' scoped>
.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 28, 49, 0.8);
  padding: 10px 10px;

  .container_left {
    width: 21.25rem;
    height: 100%;

    .container_left_top {
      position: relative;
      padding: 2.1875rem 1.6875rem;
      width: 100%;
      height: 43%;
      background: #004375;

      p {
        font-size: 1.125rem;
        color: #00deff;
      }

      .message {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;

        img {
          margin-bottom: 0.625rem;
          width: 8rem;
          height: 6rem;
          object-fit: contain;
        }

        .descript {
          color: #ffffff;
        }
      }
    }

    .container_left_bottom {
      padding: 2.1875rem 0;
      width: 21.25rem;
      height: 54%;
      position: absolute;
      background: #004375;
      bottom: 10px;

      .pc {
        position: relative;
        display: flex;
        width: 100%;
        height: 3.5625rem;
        align-items: center;
        cursor: pointer;

        span {
          margin-left: 5.125rem;
          color: #ffffff;
        }

        .icon-diannao {
          font-size: 1.25rem;
          margin-left: 2.1875rem;
          position: relative;
          top: 0.125rem;
        }

        .line {
          position: absolute;
          height: 1.5rem;
          width: 0.25rem;
          background: #00f5ff;
          right: 0;
        }

        .is_active {
          color: #00f5ff;
        }
      }

      .pc_active {
        background: #005ca0;
      }

      .log {
        display: flex;
        width: 100%;
        height: 3.5625rem;
        margin-top: 0.625rem;
        align-items: center;
        cursor: pointer;

        .icon-rizhi1 {
          font-size: 1.25rem;
          margin-left: 2.1875rem;
        }

        span {
          margin-left: 5.125rem;
          color: #ffffff;
        }

        .line {
          position: absolute;
          height: 1.5rem;
          width: 0.25rem;
          background: #00f5ff;
          right: 0;
        }

        .is_active {
          color: #00f5ff;
        }
      }

      .log_active {
        background: #005ca0;
      }
    }
  }

  .container_right {
    width: 55.5625rem;
    height: 100%;
    background: #004375;
    margin-left: 20px;
    overflow: auto;
    .title {
      display: flex;
      height: 5rem;
      border-bottom: 1px solid #0078d1;
      align-items: center;
      justify-content: center;

      p {
        color: #00f5ff;
        font-size: 1.375rem;
      }
    }
    .changesize {
      border-bottom: 1px solid #0078d1;
      padding: 2.1875rem 3.3125rem;

      .system_name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .changesize_left {
          span {
            color: #ffffff;
          }

          .inputname {
            color: #00deff;
          }
        }

        .changesize_right {
          display: flex;
          align-items: center;

          .edit {
            color: #24b5ee;
          }

          span {
            color: #ffffff;
            margin-left: 0.625rem;
          }
        }

        .changesize_right_button {
          button {
            padding: 0.4375rem 1.25rem;
            color: #ffffff;
          }

          .cancel {
            background: #001c31;
            border: 1px solid #001c31;
          }

          .enter {
            background: #0070c3;
            margin-left: 0.625rem;
            border: 1px solid #0070c3;
          }
        }
      }

      .changefontsize {
        margin-top: 1.25rem;
        display: flex;
        align-items: center;

        span {
          color: #ffffff;
        }
      }
    }

    .name {
      border-bottom: 1px solid #0078d1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.1875rem 3.3125rem;

      .changesize_left {
        span {
          color: #ffffff;
        }

        .inputname {
          color: #00deff;
        }
      }

      .changesize_right {
        display: flex;
        align-items: center;

        span {
          color: #ffffff;
          margin-left: 0.625rem;
          cursor: pointer;
        }

        .edit {
          color: #24b5ee;
        }
      }

      .changesize_right_button {
        button {
          padding: 0.4375rem 1.25rem;
          color: #ffffff;
        }

        .cancel {
          background: #001c31;
          border: 1px solid #001c31;
        }

        .enter {
          background: #0070c3;
          margin-left: 0.625rem;
          border: 1px solid #0070c3;
        }
      }
    }

    .last {
      padding: 3.25rem 3.3125rem;

      .last_container {
        display: flex;

        .logo_title {
          p {
            color: #ffffff;
          }
        }

        .logo_container {
          display: flex;

          .logo_show {
            width: 11.75rem;
            height: 11.75rem;
            background: rgba(0, 28, 49, 0.7);
            border: 1px solid #00a1a8;
            margin-left: 2.75rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .logo_descript {
            margin-left: 1.5625rem;
            height: 11.75rem;
            width: 21.875rem;
            position: relative;

            .el-button {
              background: #0085e9;
              padding: 0 0.625rem;
              margin-top: 5.625rem;
              border: 0;
              width: 9.125rem;
              height: 2.5625rem;
            }

            p {
              position: absolute;
              color: #cccccc;
              bottom: 0px;
            }
          }
        }
      }
    }
    .region {
      padding: 3.25rem 3.3125rem;
      border-bottom: 1px solid #0078d1;
      .changesize_right {
        display: flex;
        align-items: center;

        .edit {
          color: #24b5ee;
        }

        span {
          color: #ffffff;
          margin-left: 0.625rem;
        }
      }

      .changesize_right_button {
        button {
          padding: 0.4375rem 1.25rem;
          color: #ffffff;
        }

        .cancel {
          background: #001c31;
          border: 1px solid #001c31;
        }

        .enter {
          background: #0070c3;
          margin-left: 0.625rem;
          border: 1px solid #0070c3;
        }
      }
      .openArea {
        width: 100%;
        margin-bottom: 3.1875rem;
        .openAreaBox {
          width: 100%;
          .openAreaBox_R {
            width: calc(100% - 5.0625rem);
            height: 13.5rem;
            .tree {
              width: 28.125rem;
              height: 13.5rem;
              position: relative;
              .treeBox {
                width: 28.125rem;
                height: 13.5rem;
                overflow: auto;
                background: rgba(0, 28, 49, 0.7);
                border: 1px solid #00a1a8;
              }
              /deep/ .el-tree {
                background: #002845 !important;
                --el-tree-node-hover-bg-color: #002845 !important;
                color: #ffffff;
              }
              .initOpenModel {
                width: 28.125rem;
                height: 13.5rem;
                background: rgba(0, 28, 49, 0.7);
                border: 1px solid #00a1a8;
                .openModel {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 10;
                }
              }
            }

            .Edit {
              width: calc(100% - 28.125rem);
              height: 1.3125rem;
            }
          }
        }
      }
      .initArea {
        width: 100%;
        .initAreaBox {
          width: 100%;
          .initAreaBox_R {
            width: calc(100% - 5.0625rem);
            height: 2.875rem;
            /deep/ .el-input {
              --el-input-height: 2.875rem !important;
            }
            .regionText {
              width: 65%;
              height: 1.25rem;
              font-family: Source Han Sans CN;
              color: #00deff;
            }
            .Edit {
              width: calc(100% - 28.125rem);
              height: 1.3125rem;
            }
          }
        }
      }
    }
  }

  .log_part {
    .log_title {
      display: flex;
      height: 5rem;
      border-bottom: 1px solid #0078d1;
      align-items: center;
      justify-content: center;

      p {
        color: #00f5ff;
        font-size: 1.375rem;
      }
    }

    .log_container {
      padding: 0 1.625rem;

      .log_radio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2.6875rem;

        .el-button {
          border: 0;
          width: 5.75rem;
          height: 2.625rem;
          background: #0a80ca;
          border-radius: 4px;
          line-height: 0;
        }
      }

      .log_box {
        margin-top: 0.5rem;
        height: 65vh;
        background: #011321;
        border: 1px solid #004e5a;
        width: auto;
        overflow-y: auto;
        padding: 0.625rem 1.25rem;
        background: #011321;
        p {
          letter-spacing: 2px;
          font-size: 14px;
          color: #ffffff;
          padding: 0.5rem 0;
          word-break: break-all;
        }
      }
    }
  }

  //更改el-input默认样式
  .el-input {
    width: 28.125rem;
  }

  /deep/ .el-input__wrapper {
    border-radius: 0;
    background: #001c31;
    border: 1px solid #00a1a8;
    box-shadow: 0 0 0 0;
  }

  .el-input {
    /deep/.el-input__inner {
      color: #ffffff;
    }
  }

  .el-radio {
    margin-right: 3.75rem;
    color: #ffffff;
  }
}
.log_box::-webkit-scrollbar {
  width: 8px;
}

.lang_box{
  height: 100px;
  padding: 1.875rem 3.3125rem;
  border-top: 1px solid #0078d1;
  :deep(.label){
    color: #fff;
    margin: 0;
  }
}
</style>
<style lang="less" scoped>
.select-wrapper {
  display: flex;
  align-items: center;
  .label {
    font-size: 1rem;
    color: #fff;
    margin-right: 0;
  }
  .el-select {
    /deep/.el-input__wrapper {
      width: 10.5rem;
      border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: @lightBackground;
   
      box-shadow: 0 0 0 0;
      .el-input__inner {
        color: #fff;
      }
    }
  }
}
</style>